$black: #000000;
$white: #ffffff;
$primary: #0baa88;
$light: #f0f0f0;
$lighter: #f9f9f9;
$grey: #bdbdbd;
$box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14), 0 3px 1px -2px rgba(60, 75, 100, 0.12),
    0 1px 5px 0 rgba(60, 75, 100, 0.2);

$transparent: rgba(0, 0, 0, 0);
