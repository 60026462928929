body {
  font-family: "Noto Sans", sans-serif;
  overflow-y: scroll;
}
main {
  &.container-fluid {
    padding: 10rem 2rem 0 2rem;
    min-height: calc(100vh - 4rem);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  display: inline-block;
}

h2 {
  font-family: "Fjalla One", sans-serif;
  font-size: 120%;
}
p {
  line-height: 1.25rem;
}
strong {
  font-weight: bold;
}
a {
  color: inherit;
  text-decoration: none;
  .card {
    transition: box-shadow 100ms ease;
    &:hover {
      box-shadow: var(--hover-box-shadow);
    }
  }
}
hr {
  background-color: transparent;
  border: none;
  border-top: 1px solid var(--hr);
  margin-bottom: 1rem;
}
textarea {
  width: 100%;
  border: 1px solid var(--hr);
  padding: 1rem 1rem 2rem 1rem;
  background-color: var(--sidenav);
  color: var(--text);
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  resize: none;
  overflow: hidden;

  &:read-only {
    background: var(--hr);
  }
}
.btn {
  font-family: "Fjalla One", sans-serif;
  border: 1px solid var(--hr);
  padding: 0.75rem 2rem;
  background-color: var(--sidenav);
  color: var(--text);
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  &.btn-save {
    border: none;
    padding: 0.75rem 2rem;
    background: #f9f9f9;
    color: $black;
    &:hover {
      background: $light;
    }
  }
  svg {
    margin-right: 0.25rem;
  }
}

img {
  max-width: 100%;
  display: block;
}
#root {
  height: 100%;
}
.theme {
  background-color: var(--bg);
}

.card {
  padding: 2rem 2rem 1rem 2rem;
  box-shadow: var(--box-shadow);
  margin-bottom: 2rem;
  background: var(--sidenav);
  border-radius: 0.25rem;
  color: var(--text);
  position: relative;
  align-items: stretch;
  flex-grow: 1;
  overflow: hidden;
  a {
    border-bottom: 1px dotted var(--text);
  }
  &.card-collapse {
    padding: 0;
    .parent {
      position: relative;
      user-select: none;
      h2 {
        padding: 2rem;
        cursor: pointer;
        margin: 0;
      }
    }
    .collapse-content {
      padding: 2rem 2rem 1rem 2rem;
      border-top: 1px solid var(--hr);
    }
    .collapse-icon {
      right: 2rem;
      cursor: pointer;
    }
  }
  &:not(.card-collapse) {
    .collapse-toggle {
      border-bottom: 1px solid var(--hr);
      padding: 0.5rem 0;
      cursor: pointer;
    }
    .collapse {
      margin-bottom: 1rem;
      .collapse-content {
        padding-top: 1rem;
      }
    }
  }
  p {
    margin-bottom: 1rem;
  }

  &[data-alert] {
    color: white;
    display: flex;
    padding: 1rem 1rem 0 4.5rem;
    .alert-icon {
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 0.75rem;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      &.close-card {
        margin: 0;
      }
    }
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: $black;
      top: 0;
      left: 3.5rem;
      opacity: 0.1;
    }
  }
  &[data-alert="info"] {
    background: #2d6dc0;
    border: 1px solid #2d6dc0;
  }
  &[data-alert="error"] {
    background: #dd3c3c;
    border: 1px solid #dd3c3c;
  }
  &[data-alert="warning"] {
    background: #ff7f2f;
    border: 1px solid #ff7f2f;
  }
  &[data-alert="success"] {
    background: #03a783;
    border: 1px solid #03a783;
  }
  .close-card {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    font-size: 125%;
    opacity: 0.2;
    color: $black;
  }
  .card-title-group {
    h3 {
      font-weight: 400;
      font-style: italic;
    }
    hr {
      margin-bottom: 2rem;
      border-width: 2px;
    }
  }
  .card-title {
    display: flex;
    justify-content: space-between;
    span {
      color: #bdbdbd;
    }
  }
  .card-icon {
    border-color: transparent transparent $primary transparent;
    border-style: solid;
    border-width: 0 4rem 4rem 4rem;
    height: 0px;
    width: 0px;
    top: -1rem;
    right: -3rem;
    position: absolute;
    transform: rotate(45deg);
    svg {
      position: absolute;
      right: -0.75rem;
      color: white;
      opacity: 0.9;
      top: 2rem;
      font-size: 125%;
      transform: rotate(-45deg);
    }
  }
  .card-footer {
    width: calc(100% + 4rem);
    margin: 1rem 0 -1rem -2rem;
    margin-top: 1rem;
    padding: 2rem;
    background: var(--sub-menu-item);
    .card-buttons {
      display: flex;
      justify-content: space-between;
    }
  }
}
.muted-textarea {
  background: var(--hr);
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
  color: var(--text);
  line-height: 1.25;
}
.collapse {
  .collapse-content-parent {
    overflow: hidden;
    transition: height 200ms ease;
  }
  .collapse-toggle {
    user-select: none;
    position: relative;
    cursor: pointer;
    &.collapsed {
      .collapse-icon {
        -webkit-transform: translateY(-50%) rotate(90deg);
        -ms-transform: translateY(-50%) rotate(90deg);
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }
  .collapse-icon {
    transition: transform 100ms ease;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(0);
    -ms-transform: translateY(-50%) rotate(0);
    transform: translateY(-50%) rotate(0);
    color: var(--text);
  }
}

.breadcrumb {
  box-shadow: var(--box-shadow);
  background: var(--bg-light);
  position: fixed;
  z-index: 7;
  right: 0;
  left: 0;
  top: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  font-size: 90%;
  color: $grey;
  border-bottom: 1px solid var(--hr-dark);
  .breadcrumb-item {
    display: inline-block;
    &:not(:last-of-type) {
      &:after {
        content: "/";
        margin: 0 1rem;
        color: var(--text);
      }
      a {
        color: var(--text);
      }
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .col-12 {
    flex-direction: row;
  }
  .breadcrumb-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .save-entry {
    display: flex;
    align-items: center;
    span {
      margin-right: 1rem;
      display: block;
      display: flex;
      font-size: 80%;
      svg {
        margin-right: 0.25rem;
      }
    }
  }
  .breadcrumb-arrows {
    margin-left: 1rem;
    color: var(--text);
    font-size: 200%;
    display: flex;
    div {
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

//nav
.top-nav {
  height: 4rem;
  background: var(--sidenav);
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9;
  right: 0;
  left: 0;
  top: 0;
  border-bottom: 1px solid var(--hr);
  color: var(--text);
  user-select: none;
  a {
    color: $white;
  }
  svg {
    height: 1.5em;
    width: 1.5em;
  }
  [class^="col-"] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.menu-toggle {
  cursor: pointer;
}
.side-nav {
  position: fixed;
  height: calc(100vh - 4rem);
  width: 250px;
  background: var(--sidenav);
  transition: transform 200ms ease;
  transform: translateX(-100%);
  z-index: 8;
  box-shadow: var(--box-shadow);
  top: 4rem;
  border-right: 1px solid var(--hr);
  user-select: none;
  overflow: auto;
  a {
    display: block;
    position: relative;
    text-decoration: none;
    color: var(--text);
    padding: 1.5rem;
    &.active {
      background: var(--menu-item-active);
    }
    &:hover {
      background: var(--menu-item-active);
    }
  }
  &.open {
    transform: translateX(0);
  }
  .collapse-icon {
    right: 0.5rem;
  }
}
.menu-profile {
  display: flex;
  position: relative;
  color: var(--text);
  padding: 1rem;
  border-bottom: 1px solid var(--hr);
  svg {
    margin-right: 0.5rem;
  }
}
.menu-item {
  position: relative;
  display: flex;
  svg {
    margin-right: 0.5rem;
  }
  &.parent {
    display: flex;
    position: relative;
    text-decoration: none;
    color: var(--text);
    padding: 1.5rem;
    cursor: pointer;
    + .sub-menu {
      overflow: hidden;
      transition: height 200ms ease;
    }

    svg {
      transition: transform 100ms ease;
      margin-right: 0.5rem;
    }
    &:hover {
      background: var(--sub-menu-item-active);
    }
    + .sub-menu-collapse-container {
      transition: height 200ms ease;
    }
  }
}
.sub-menu-item {
  position: relative;
  background: var(--sub-menu-item);
  a {
    padding-left: 2rem;
    display: flex;
    max-width: 100%;
    &.active {
      background: var(--menu-item-active);
    }
  }
  svg {
    margin-right: 0.5rem;
  }
}

.login-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 1rem;
  background: #f0f0f0;

  &.loading {
    cursor: wait;
  }
}

.login-form {
  padding: 3rem 1.5rem;
  margin: 0 auto;
  display: flex;
  background: $white;
  box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14), 0 3px 1px -2px rgba(60, 75, 100, 0.12),
    0 1px 5px 0 rgba(60, 75, 100, 0.2);
  border-radius: 0.25rem;
  width: 320px;
  max-width: 100%;
  h1 {
    margin-bottom: 2rem;
    text-align: center;
  }

  p {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 0.8rem;
  }
  form {
    width: 100%;
  }
  div {
    position: relative;
  }
  label {
    font-size: 80%;
    font-weight: bold;
    margin-bottom: 0.25rem;
    display: block;
  }
  input {
    border-radius: 0;
    padding: 0 0.5rem;
    width: 100%;
    box-sizing: border-box;
    height: 2.75rem;
    border: 0;
    margin-bottom: 1.5rem;
    background: $light;
  }
  input[type="submit"] {
    border-radius: 0;
    background: $black;
    border-bottom: 0;
    color: $white;
    cursor: pointer;
    font-weight: bold;
    margin-top: 1rem;
    border-radius: 0.25rem;
  }

  .remember-me-checkbox {
    display: inline-block;
    margin: 0 1rem 0.25rem 0;
    padding: 0 0 0 1.35rem;
    cursor: pointer;

    &:after {
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
      left: 0;
      top: 0;
      content: " ";
      border: 1px solid $grey;
      border-radius: 50%;
      background: $transparent;
      @include ani;
    }

    &.active {
      &:after {
        border: 1px solid $black;
        background: $black;
      }
    }
  }

  transform: scale(1);
  opacity: 1;
  @include ani;
  pointer-events: all;

  &.loading {
    pointer-events: none;
    transform: scale(0.9);
    opacity: 0.3;
  }
}

.toggle-switch {
  input {
    display: none;
  }
  label {
    cursor: pointer;
    display: block;
    border: 1px solid var(--toggle);
    border-radius: 1rem;
    background-color: var(--toggle);
    width: 2.75rem;
    height: 1.25rem;
    position: relative;
    transition: all 0.2s;
    svg {
      color: var(--sidenav);
      width: 1rem;
      height: auto;
      position: absolute;
      top: 1px;
      left: 3px;
      transition: none;
      &:last-of-type {
        top: 1px;
        right: 3px;
        left: auto;
      }
    }
    &:after {
      content: "";
      background-color: var(--sidenav);
      border-radius: 50%;
      width: 1.15rem;
      height: 1.14rem;
      position: absolute;
      left: 1px;
      transition: left 100ms ease;
    }
  }

  input {
    &:checked {
      & + label {
        background: $white;
        background: $white;
        &:after {
          left: 1.525rem;
          top: 0;
          background: #1a1a1b;
          border: 1px solid var(--text);
        }
      }
    }
  }
}

.footer {
  height: 4rem;
  color: var(--text);
  background: var(--bg-light);
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
  border-top: 1px solid var(--hr-dark);
}

.top-nav-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
  }
  .logo {
    margin-left: 2rem;
  }
}

main {
  .fit-row {
    width: calc(100% + 30px);
    transition-duration: 200ms;
    transition-property: margin-left, width;
  }
  + footer {
    .fit-row {
      width: calc(100% + 30px);
      transition-duration: 200ms;
      transition-property: margin-left, width;
    }
  }
}

@media only screen and (min-width: 768px) {
  .open {
    + main {
      .fit-row {
        width: calc(100% - 222px);
        margin-left: 236px;
      }
      + footer {
        .fit-row {
          width: calc(100% - 250px);
          margin-left: 235px;
        }
      }
    }
  }
}

//gallery

.gallery-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -1rem;
  width: auto;
}
.gallery-column {
  padding-left: 1rem;
  background-clip: padding-box;
  img {
    margin-bottom: 1rem;
    border-radius: 0.25rem;
  }
}

.lightbox-container {
  position: fixed;
  background: var(--lightbox-bg);
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 75ms, opacity 75ms;
  pointer-events: none;
  &.open {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 75ms;
    pointer-events: initial;
  }
  .slide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms ease;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    img {
      width: auto;
      max-width: 100%;
      max-height: calc(100vh - 16rem);
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      box-shadow: var(--box-shadow);
    }
  }
  .lightbox-dot {
    background: var(--bg-light);
    border: 2px solid var(--lightbox-dot);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin: 0.25rem;
    cursor: pointer;

    .public-notice{display: none;}
    .private-notice{display: block;}

    &.active {
      background: var(--lightbox-dot);
      .public-notice{display: block;}
      .private-notice{display: none;}
    }
    &.dead {
      pointer-events: none !important;
    }
  }
  .slides-container {
    width: 920px;
    max-width: calc(100% - 4rem);
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: calc(100vh - 4rem);
  }
  .next,
  .prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid var(--lightbox-dot);
    background: transparent;
    color: var(--text);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 125%;
    cursor: pointer;
  }
  .prev {
    left: -1.5rem;
  }
  .next {
    right: -1.5rem;
  }
  .lightbox-dots {
    display: flex;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    &.alt {
      top: 1rem;
      bottom: reset;
    }
  }
  .lightbox-close {
    color: var(--text);
    font-size: 200%;
    position: absolute;
    right: 3rem;
    top: 2rem;
    cursor: pointer;
    z-index: 1;
  }
  .slide-file {
    position: absolute;
    bottom: 3.5rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 80%;
    span {
      font-style: italic;
    }

    &.alt {
      top: 2.5rem;
      white-space: nowrap;
      bottom: reset;
    }
  }
}

.gallery {
  display: flex;
  img {
    max-width: 100%;
    cursor: pointer;
  }
  [class^="col-"] {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    margin-bottom: 0.25rem;
  }
  &.row {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.loading {
  display: flex;
  span {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: var(--lightbox-dot);
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    margin-right: 0.25rem;
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

//xthusiast

.state-checkboxes {
  margin: 2rem 0 1rem 0;
  div {
    margin-bottom: 0.75rem;
    display: flex;
  }
  input {
    margin: 0;
    width: 16px;
    height: 16px;
  }
  label {
    padding: 0 0 0 0.5rem;
  }
  input,
  label {
    cursor: pointer;
    user-select: none;
  }
}
.xthusiast-social {
  margin-bottom: 2rem;
  margin-top: 2rem;
  .collapse {
    margin-top: 1rem;
  }
  svg {
    vertical-align: top;
  }
  span {
    margin-left: 0.5rem;
  }
}

.center-align {
  text-align: center !important;
}

.logout-option {
  display: inline-block;
  width: 30%;
  border: 1px solid var(--text);
  background-color: $transparent;
  padding: 1em;
  margin: 1em 1em 1em 0;
  cursor: pointer;
  @include ani;
  text-align: center;
  font-weight: bold;
  color: var(--text);

  &:hover {
    border: 1px solid $primary;
    background-color: $primary;
    color: $white;
  }
}

.data-entry-link {
  display: block;
  width: 100%;
  height: 100%;
  border: none !important;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  white-space: pre-line;
  line-height: 130%;
}

.table {
  width: 100%;
  max-width: 100%;
  th {
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid var(--hr);
    padding: 0.5rem 0 0.5rem 0.25rem;

    &:first-of-type {
      padding: 1rem 0 1rem 0;
      border-top-left-radius: 0.25rem;
    }
    &:last-of-type {
      border-top-right-radius: 0.25rem;
    }
    input {
      margin-top: 0.5rem;
      background: var(--hr);
      padding: 0.25rem;
      border-radius: 0.25rem;
      color: var(--text);
      border: 1px solid var(--hr);
      background: transparent;
      width:80%;
    }
    .filter-title {
      span {
        display: flex;
        width: 100%;
        svg {
          margin-left: 0.25rem;
        }
      }
    }
  }

  td {
    position: relative;
    padding: 0.5rem 1rem 0.5rem 0.25rem;
    svg {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    &:first-of-type {
      padding: 1rem 0 1rem 0;
    }

    &:first-of-type {
      a {
        &.other-files-link {
          display: inline-block;
          appearance: none;
          border-bottom: none;
          color: var(--text);
          margin: 0 0 0 1.5rem;
        }
      }
    }
  }
  tr {
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--hr);
    }
  }
}
.pagination {
  margin-top: 1rem;
  display: flex;
  input {
    width: 76px;
    margin-left: 0.5rem;
    height: 1.5rem;
    border: 1px solid var(--hr);
    background-color: var(--sidenav);
    color: var(--text);
  }
  select {
    margin-left: 0.5rem;
    height: 1.5rem;
    border: 1px solid var(--hr);
    background-color: var(--sidenav);
    color: var(--text);
    cursor: pointer;
  }
  button {
    background: var(--hr);
    color: var(--text);
    border: 0;
    font-size: 120%;
    cursor: pointer;
    margin-right: 0.5rem;
    border-radius: 0.25rem;
    display: inline-flex;
  }
  span {
    margin: 0 0.25rem;
    display: flex;
    align-items: center;
  }
  .pagination-nav {
    display: flex;
    align-items: center;
  }
}
h3.no-entries-header {
  color: var(--text);
}

.other-comment {
  display: block;
  width: 100%;
}

.other-comment-header {
  color: var(--text);
  width: 100%;
  border: 1px solid var(--textarea-header);
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom: 0;
  margin: 0;
  padding: 1rem;
  background: var(--textarea-header);

  small {
    font-style: italic;
    font-size: 80%;
    display: flex;
    svg {
      margin-right: 0.25rem;
    }
  }
  + textarea {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.checkbox {
  position: relative;
  margin-bottom: 1rem;
  label {
    padding-left: 2rem;
    display: block;
  }
  input {
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 1rem;
    height: 1rem;
    &:checked {
      + .input-helper {
        &:before {
          border-color: $black;
          background-color: $black;
        }
        &:after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
  .input-helper {
    &:before,
    &:after {
      position: absolute;
      content: "";
    }
    &:before {
      left: 0;
      border: 1px solid #9b9b9b;
      top: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 2px;
      color: $white;
    }
    &:after {
      content: "\2714";
      left: 3px;
      top: 2px;
      color: $white;
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
      font-size: 80%;
    }
  }
}

.unsavedChangesNotice {
  opacity: 0;
  @include ani;
  color: $primary;
  font-size: 1.05rem !important;
  will-change: transform;

  &.showing-warning {
    opacity: 1;
    animation: blinker 2000ms cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite;
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 0.75;
    transform: scale(0.97);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.edit-entry-trigger {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 1.6rem;
  right: 1.5rem;
  cursor: pointer;
  color: $primary;
  @include ani;
  transform: scale(1);
  will-change: transform;

  &:hover {
    color: var(--text);
    transform: scale(11.15rem);
  }
}

.admin-text-override {
  width: calc(100% + 1.75rem);
  border: 1px solid var(--hr);
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: var(--sidenav);
  color: var(--text);
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  border-radius: 0.25rem;
  resize: none;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transform: translateX(-0.75rem);

  @include ani;

  &:focus {
    border: 1px solid $primary;
  }
}

.editable-text-area-container {
  display: block;
  position: relative;
}


.action-toggle-container{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.action-toggle-cell{
  display: flex;
  margin:1rem 0 2rem 0;
  flex-wrap: wrap;
  justify-content: center;
}

.action-toggle-label{
  display: block;
  text-align: center;
  width:100%;
  margin:0 0 0.5rem 0rem!important;
  padding:0 0 0 0!important;
  font-size: 0.9rem;
}

.hide-el{
  opacity: 0!important;
}